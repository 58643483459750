export const experience = {
  title: "Experience",
  employers: [
    {
      name: "Envestnet Tamarac",
      location: "Seattle, WA",
      positions: [
        {
          title: "UI Engineering Team Lead",
          start: "June 2019",
          end: "Present"
        },
        {
          title: "Senior UI Engineer",
          start: "April 2017",
          end: "June 2019"
        }
      ],
      highlights: [
        "Own UI feature development for financial trading and reporting software with over $1.2 trillion in assets under management",
        "Collaborate with product owners and designers to conceptualize, prototype and build new features",
        "Streamline UX by integrating trading and reporting features",
        "Build and maintain reusable component libraries with React, Redux, Bootstrap and Storybook",
        "Write React unit tests using Jest and Enzyme",
        "Automate React component integration testing using Cypress",
        "Automate end-to-end mobile testing using Appium, Mocha and Chai",
        "Develop web views for Android and iOS applications",
        "Improve front end code quality via paired programming and code reviews",
        "Work with backend engineers to architect and document RESTful APIs",
        "Regularly demo new UI features for management and product teams",
        "Give presentations on UI topics to 100+ member engineering team",
        "Administer technical interviews for prospective UI engineers",
        "Onboard new team members"
      ]
    },
    {
      name: "Possible",
      location: "Seattle, WA",
      positions: [
        {
          title: "Senior Developer",
          start: "August 2013",
          end: "April 2017"
        }
      ],
      description: "Clients included AT&T, Coca-Cola, and TDAmeritrade",
      highlights: [
        "Collaborated across disciplines (creative, strategy, analytics, etc.) to concept, prototype and build user interfaces using appropriate technologies",
        "Worked with Program Managers and discipline leads to ensure projects are scoped and estimated correctly and completed on time and to specification",
        "Documented and socialized best practices related to front end development",
        "Evaluated technical feasibility of designed solutions",
        "Delegated tasks to junior developers and review their code",
        "Implemented tools and developed processes to ensure code quality",
        "Packaged code and supported deployment of releases",
        "Supported new business initiatives by providing technical input",
        "Collaborated with remote development teams globally"
      ]
    },
    {
      name: "Nordstrom",
      location: "Seattle, WA",
      positions: [
        {
          title: "Senior Interactive Developer",
          start: "March 2013",
          end: "August 2013"
        }
      ],
      highlights: [
        "Led front end development for Nordstrom.com Personalization",
        "Provided coaching and mentorship for junior developers",
        "Recommended best practices for the Interactive Development team"
      ]
    },
    {
      name: "Roundarch",
      location: "Chicago, IL",
      positions: [
        {
          title: "Senior Consultant, Technical Lead",
          start: "July 2011",
          end: "March 2013"
        },
        {
          title: "Consultant II, Flash Developer",
          start: "March 2010",
          end: "July 2011"
        }
      ],
      description: "Clients included William Blair and Bloomberg.",
      highlights: [
        "Led front end development for single-page web apps and websites",
        "Made architectural decisions about application front ends",
        "Oversaw the work of junior developers",
        "Worked effectively  with remote teams spanning various time zones and on-site with client teams in Chicago and New York",
        "Transitioned from Flash developer to web standards developer"
      ]
    },
    {
      name: "Oncall Interactive",
      location: "Chicago, IL",
      positions: [
        {
          title: "Senior Flash Developer",
          start: "August 2008",
          end: "March 2010"
        }
      ],
      description:
        "Clients included Nike, McDonalds, FX, and the Chicago Symphony Orchestra",
      highlights: [
        "Developed web sites and applications using Flash (AS2/3) and web standards",
        "Created motion graphics for digital signage in Times Square",
        "Recommended best practices for the Interactive Development team"
      ]
    },
    {
      name: "Anode",
      location: "Nashville, TN",
      positions: [
        {
          title: "Flash Developer",
          start: "February 2008",
          end: "August 2008"
        }
      ],
      highlights: [
        "Developed interactive touch screen kiosks and digital signage for museum installations using Flash (ActionScript 2)"
      ]
    }
  ]
};
