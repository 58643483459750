import React from "react";

export const Sidebar = () => {
  return (
    <aside>
      <div class="contents">
        <div class="details">
          <h3>Skills and Interests</h3>
          <p>
            JavaScript, React, Redux, Jest, Enzyme, Cypress, jQuery, CSS3, SCSS,
            data grids
          </p>
        </div>
      </div>
    </aside>
  );
};
